import { useState} from "react"
import * as FileSaver from  'file-saver'
import XLSX from 'sheetjs-style'
import { Button, Modal, Segment } from "semantic-ui-react"
import { checkStockAvailability } from "../../../constant"
const FILETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXTENSION = '.xlsx'

export const Export2Excel = ({ data: excelData, label }) => {
    const [open, setOpen] = useState(false)
    return <>
        <Button color="pink" icon='file excel' size='mini' onClick={() => setOpen(true)} content={'Сохранить в Excel'}/>
        <Modal
            size='tiny'
            dimmer='blurring'
            closeOnDimmerClick={false}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>Экспорт в файл Excel</Modal.Header>
            <Modal.Content>
                <Segment padded>

                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Экспорт" onClick={() => exportToExcel(excelData, label)} positive />
                <Button negative onClick={() => setOpen(false)}>Закрыть</Button>
            </Modal.Actions>
        </Modal>
    </>
}

const exportToExcel = async (data, fileName = 'test') => {
    const ws = XLSX.utils.json_to_sheet(data.map(({ brand, article, description, quant, price, total, status, view, stock }, i) => {

        const [, isInStock] = checkStockAvailability(stock, price)
        const inProcess = status !== 3 || !view

        return ({
            '№': i + 1,
            'Производитель': brand,
            'Артикул': article,
            'Наименование': description,
            'Количество': quant,
            'Наличие': inProcess ? 'В обработке' : isInStock,
            'Цена/ед. (Евро)': inProcess ? '': price,
            'Сумма': inProcess ? '' : total
        })
    }))
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" })
    FileSaver.saveAs(new Blob([excelBuffer], { type: FILETYPE }), fileName + EXTENSION)
}