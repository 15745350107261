import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs"
import { useQuery } from 'react-query'
import {
    Button, Table, Grid, Segment, Accordion, List, Label, Icon, Divider, Pagination, Progress, Input, Popup, Header
} from "semantic-ui-react"
import useDebounced from '../../hooks/useDebouncedFunction'
import { Api, authUuid } from "../../_helpers"
import { CreateModal } from "./component/CreateModal";
import { Export2Excel } from "./component/Export2Excel"
import { STATUS_OPTIONS, checkStockAvailability } from "../../constant"
import {cartActions} from "../../_store";
import {BasketModal} from "../Orders/Basket";

const processColor = (percent) => {
    if (isNaN(percent) || +percent === 0) return 'red'
    return +percent === 100 ? 'green' : 'yellow'
}

const StockStatus = (intStatus, inProcess) => {
    if (inProcess) {
        return { color: 'orange', name: 'wait' }
    }
    if (intStatus === 0) return { color: 'red', name: 'close' }
    if (intStatus === 1) return { color: 'green', name: 'checkmark' }
    return { color: 'yellow', name: 'exclamation triangle' }
}

export const Requests = () => {
    const dispatch = useDispatch()
    const cartItem = useSelector(({cart}) => cart.item)
    const [requestList, setRequestList] = useState({
        total: 0, list: []
    })

    const [activePage, setActivePage] = useState(0)
    const [active, setActive] = useState([])

    const [filterDate, setFilterDate] = useState(null)
    const [filterDocs, setFilterDocs] = useState(null)
    const [filterArts, setFilterArts] = useState('')

    const inputFindOrder = useDebounced((val) => setFilterDocs(val), 800)
    const inputFindParts = useDebounced((val) => setFilterArts(val), 800)

    const { error, isFetching, refetch } = useQuery(['requests.list', [activePage, filterDate, filterDocs, filterArts]],
        () => Api().get('/requests', { params: authUuid({
                page: activePage,
                ...(filterDocs && { filterDocs }),
                ...(filterDate && { filterDate }),
                ...(filterArts && { filterArts })
        })}),
        {
            onSuccess: ({ data }) => setRequestList(data),
            refetchOnWindowFocus: true
        })

    const panels = requestList?.list?.map(({ctime, docid, total, label, speed, view,  success, amount}, i) => {
        const percent = (100 * success / total).toFixed(2)
        return ({
            key: 'panel' + i,
            onTitleClick: () => setActive(prev => prev.includes(i) ? prev.filter(el => el !== i) : [...prev, i]),
            title: {
                style: { padding: 0 },
                icon: false,
                content: <List selection>
                    <List.Item>
                        <List.Content>
                            <Grid verticalAlign='middle' divided columns={'equal'} centered>
                                <Grid.Row stretched>
                                    <Grid.Column width={2} textAlign="center">
                                        {dayjs(ctime).format('DD.MM.YYYY HH:mm')}
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Label style={{ textAlign: 'center' }}>{label || '-'}</Label>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Label style={{ textAlign: 'right' }}>{total || 0} шт.</Label>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Label style={{ textAlign: 'right' }} {...(amount > 0 && { color: 'blue'})}>
                                            {amount || 0} €
                                        </Label>
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Popup content={speed ? 'Медленная проценка' : 'Быстрая проценка' } position='top center' trigger={
                                            <Label style={{ textAlign: 'center' }} {...(speed && { color: 'green'})}>
                                                {speed ? 'slow' : 'fast' }
                                            </Label>
                                        } />
                                    </Grid.Column>
                                    <Grid.Column width={0} style={{ alignItems: 'center' }}>
                                        <Popup content={'Статус запроса'} position='top center' trigger={
                                            view ? <Icon color='green' name='checkmark' size='large' /> : <Icon color='orange' name='wait' size='large' />
                                        } />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Progress style={{ margin: 0 }} percent={percent} progress color={processColor(percent)} />
                                    </Grid.Column>
                                    <Grid.Column width={1} textAlign={'right'}>
                                        <div>
                                            <Icon name={"angle" + (active.includes(i) ? " up" : " down")} circular/>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </List.Content>
                    </List.Item>
                </List>
            },
            content: { content: active.includes(i) && <TableRequest orderUid={docid} label={label} /> }
        })
    })

    return <Segment padded>
        <CreateModal close={refetch} />
        <Button
            floated={'right'}
            onClick={() => dispatch(cartActions.open(true))}
            content={`[${cartItem.length}]`}
            icon='shop'
        />
        <BasketModal close={() => {}} />
        <Divider hidden style={{ margin: '0.3em' }} />
        <Segment padded secondary>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <label>Дата</label>
                        <Input
                            type="date" fluid
                            value={filterDate} onChange={(_, { value }) => setFilterDate(value)}
                            placeholder='Дата' max={dayjs().format('YYYY-MM-DD')} min="2023-09-01" />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <label>Номер запроса</label>
                        <Input
                            fluid
                            onChange={(_, { value }) => inputFindOrder(value)}
                            placeholder='' width={2} icon='search' />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <label>Артикул</label>
                        <Input
                            fluid
                            onChange={(_, { value }) => inputFindParts(value)}
                            placeholder=''
                            icon={filterArts?.length ? { name: 'close', link: true, onClick: () => inputFindParts('') } : false}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Divider hidden fitted />
        <Segment loading={isFetching} padded={isFetching}>
            <Accordion exclusive={false} fluid panels={panels} activeIndex={active} />
        </Segment>
        {requestList.total > 1 && <Segment textAlign="center">
            <Pagination
                activePage={activePage + 1}
                totalPages={requestList.total} siblingRange={10} onPageChange={(_, { activePage }) => setActivePage(activePage - 1)}
            />
        </Segment>}
    </Segment>
}

const TableRequest = ({ orderUid, label }) => {
    const dispatch = useDispatch()
    const cartItem = useSelector(({cart}) => cart.item)
    const [reqList, setReqList] = useState([])
    const { isFetching } = useQuery(['request.info', [orderUid]],
        () => Api().get('/requests/info', { params: authUuid({ docid: orderUid })}), {
            enabled: Boolean(orderUid),
            onSuccess: ({ data }) => setReqList(data),
            refetchOnWindowFocus: true
        })

    const handlerAdd = (val) => dispatch(cartActions.add(val))

    return <Segment loading={isFetching} secondary basic>
        <Table celled compact unstackable striped selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ minWidth: '20px' }}/>
                <Table.HeaderCell style={{ minWidth: '180px' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '180px' }}>Артикул</Table.HeaderCell>
                <Table.HeaderCell width={5}>Наименование</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '80px' }} textAlign="right">Кол-во</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '80px' }} textAlign="center">Наличие</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '120px' }} textAlign="right">Срок доставки</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '80px' }} textAlign="right">Цена/ед. (Евро)</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '100px' }} textAlign="right">Сумма</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" style={{ minWidth: '100px' }}>Статус</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" style={{ minWidth: '40px' }} />
{/*                <Table.HeaderCell textAlign="center" width={1} style={{ width: '80px' }}>
                    <ExportExcelFile data={list} />
                </Table.HeaderCell>*/}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {reqList.map(({id, article, brand, description, quant, quant2, primal, price, total, status, guid, view, stock }) => {
                const { label: { color = 'white' }, text } = STATUS_OPTIONS[status]
                const isExistCart = cartItem.some(el => el.guid === guid)

                const [intStatus, isInStock] = checkStockAvailability(stock, price)
                const inProcess = status !== 3 || !view

                return <Table.Row key={id} negative={quant2 === 0}>
                    <Table.Cell textAlign="center">
                        <Popup content={primal ? 'Запрошенный номер' : 'Аналог'} position='top center' trigger={<Icon name='circle' color={primal ? 'green' : 'orange'} />} />
                    </Table.Cell>
                    <Table.Cell>{brand}</Table.Cell>
                    <Table.Cell>{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Popup content={inProcess ? 'В обработке' : isInStock}
                               position='top center'
                               trigger={<Icon {...StockStatus(intStatus,inProcess)} size='large' />}
                        />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        {inProcess ? 'В обработке' : isInStock}
                    </Table.Cell>
                    <Table.Cell textAlign="right">{view ? (price || '-') : '-'}</Table.Cell>
                    <Table.Cell textAlign="right">{view ? (total || '-') : '-'}</Table.Cell>
                    <Table.Cell textAlign="center">
                        {view && <Label color={color} content={text}/>}
                    </Table.Cell>
                    <Table.Cell>
                        <Button
                            disabled={Boolean(!quant) || !view} fluid
                            onClick={() => isExistCart ? dispatch(cartActions.open(true)): handlerAdd({quant: +quant, guid})} icon='cart'
                            positive={isExistCart}
                        />
                    </Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell colSpan='5'>
                    <Export2Excel data={reqList} label={label} />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='3' textAlign={'right'}>
                    Итого:
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='1' textAlign={'right'}>
                    <Header as='h4'>{
                        new Intl.NumberFormat("ru").format(reqList.reduce((sum, { total }) => sum + +total, 0))
                    }</Header>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='3'/>
            </Table.Row>
        </Table.Footer>
    </Table>
    </Segment>
}