const STATUS = [
    { value: 0, key: 'created' },
    { value: 1, key: 'adopted' },
    { value: 2, key: 'working' },
    { value: 3, key: 'success' }
]
const STATUS_LABEL = [
    { text: 'Создан', label: { color: 'red', empty: true, circular: true } },
    { text: 'Принят', label: { color: 'green', empty: true, circular: true } },
    { text: 'В обработке', label: { color: 'olive', empty: true, circular: true } },
    { text: 'Обработан', label: { color: 'violet', empty: true, circular: true } },
]
export const STATUS_OPTIONS = STATUS.map(el => ({...el, ...STATUS_LABEL[el.value] }))


const CONDITIONS_SUCCESS = ['склад', 'stock']
const CONDITIONS_CANSEL  = ['lead time not available', 'нет', 'нет в наличии']

const CONDITIONS_DEFAULT  = ['недел', 'дней', 'дня', 'месяц']

const CONDITIONS_SUCCESS_EXT = [null, '', 'в наличии', 'В наличии']

export function checkStockAvailability(value, price) {
    if ((CONDITIONS_SUCCESS_EXT.includes(value) && price > 0) || CONDITIONS_SUCCESS.some(el => value?.toLowerCase().includes(el))) {
        return [1, 'В наличии']
    }
    if (CONDITIONS_CANSEL.some(el => value?.toLowerCase().includes(el))) {
        return [0, 'Нет в наличии']
    }
    if (CONDITIONS_DEFAULT.some(el => value?.toLowerCase().includes(el))) {
        return [1, value]
    }
    return [-1, 'Требуется уточнение']
}